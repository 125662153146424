exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fotostudio-js": () => import("./../../../src/pages/fotostudio.js" /* webpackChunkName: "component---src-pages-fotostudio-js" */),
  "component---src-pages-hotels-js": () => import("./../../../src/pages/hotels.js" /* webpackChunkName: "component---src-pages-hotels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-rad-js": () => import("./../../../src/pages/rad.js" /* webpackChunkName: "component---src-pages-rad-js" */),
  "component---src-pages-serien-js": () => import("./../../../src/pages/serien.js" /* webpackChunkName: "component---src-pages-serien-js" */)
}

